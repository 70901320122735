import { Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UsersToolbar } from '.';
import { userActions } from '../../../../_actions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const UserDetail = props => {
  
  const { id } = props;

  const classes = useStyles();

  const user = useSelector(state => state.users.item);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.getOne(id), null);
  }, [id]);

  const valueOrEmpty = (vaule)  => {
    return vaule ? vaule : " ";
  }

 

  const [formEnabled, setFormEnabled] = useState(false);

  const toggleFormEnabled = () => {
    setFormEnabled(!formEnabled);
  };

  const getReadOnly = () => {
    return ! formEnabled;
  };

  return (
    <div className={classes.root}>
       {user ? (
        <div>
          <UsersToolbar 
            userId={user.id}
            username={user.name + " " + valueOrEmpty(user.surname)}
            formEnabled={formEnabled}
            toggleFormEnabled={toggleFormEnabled}
          />
          <div className={classes.content}>
            <Paper >
              <TextField
                label="Name"
                defaultValue={user.name}
                className={classes.textField}
                margin="normal"
                InputProps={{
                  readOnly: getReadOnly(),
                  disableUnderline:  getReadOnly(),
                }}
              />
              <TextField
                label="Surname"
                defaultValue={valueOrEmpty(user.surname)}
                className={classes.textField}
                margin="normal"
                InputProps={{
                  readOnly: getReadOnly(),
                  disableUnderline:  getReadOnly(),
                }}
              />
              <TextField
                label="Email"
                defaultValue={valueOrEmpty(user.email)}
                className={classes.textField}
                margin="normal"
                InputProps={{
                  readOnly: getReadOnly(),
                  disableUnderline:  getReadOnly(),
                }}
              />z
            </Paper>
         </div>
       </div>
      ) : (
        <div>
          <div className={classes.content}>
            <Paper>
              <Typography variant="h4" >Not foud</Typography>
            </Paper>
          </div>
        </div>
      )}
      </div>
  );
};

export default UserDetail;



