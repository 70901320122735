export const userConstants = {

    ACTION_SAVE: 'USER_ACTION_SAVE',
    ACTION_UPDATE_PASSWORD: 'USER_ACTION_UPDATE_PASSWORD',

    KICK_REQUEST: 'USER_KICK_REQUEST',
    KICK_SUCCESS: 'USER_KICK_SUCCESS',
    KICK_FAILURE: 'USER_KICK_FAILURE',

    GETALL_REQUEST: 'USER_GETALL_REQUEST',
    GETALL_SUCCESS: 'USER_GETALL_SUCCESS',
    GETALL_FAILURE: 'USER_GETALL_FAILURE',

    GETONE_REQUEST: 'USER_GETONE_REQUEST',
    GETONE_SUCCESS: 'USER_GETONE_SUCCESS',
    GETONE_FAILURE: 'USER_GETONE_FAILURE',

    DELETE_REQUEST: 'USER_DELETE_REQUEST',
    DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    DELETE_FAILURE: 'USER_DELETE_FAILURE',
    
    LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    RECOVER_REQUEST: 'USER_RECOVER_REQUEST',
    RECOVER_SUCCESS: 'USER_RECOVER_SUCCESS',
    RECOVER_FAILURE: 'USER_RECOVER_FAILURE',
    
    LOGOUT: 'USER_LOGOUT',

  
};
