import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { UsersToolbar } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../../_actions';
import { TextField, Paper, Tab, Tabs, Typography, Box, Divider } from '@material-ui/core';
import validate from 'validate.js';


const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  surname: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const UserEdit = props => {

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });


  const formValidation = () => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }

  const hasError = (field) => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  }

  useEffect(() => {
    formValidation();
  }, [formState.values,formState.touched]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };



  const dispatch = useDispatch();

  const handleRegister = event => {
    if(event.type === 'keypress' && event.key !== 'Enter'){
      return;
    }
    event.preventDefault();
    setFormState(formState => ({
      ...formState,
      touched: schema
    }));
 
    const { name, surname, email } = formState.values;
    
    if (name && surname && email ) {
      dispatch(userActions.register({
        name: name,
        surname: surname,
        email: email
      })
      );
    }
  };


  return (
    <div className={classes.root}>
      <div>
        <UsersToolbar
          userId="0"
          username="New User"
          onSubmit={handleRegister}
        />
        <form
          className={classes.form}
          onKeyPress={handleRegister}
        >
          <div className={classes.content}>
            <Paper >


              <TextField
                name="name"
                label="Name"
                value={formState.values.name || ''}
                error={hasError('name')}
                helperText={
                  hasError('name') ? formState.errors.name[0] : null
                }

                className={classes.textField}
                margin="normal"
                onChange={handleChange}
                type="text"

              />
              <TextField
                name="surname"
                label="Surname"
                value={formState.values.surname || ''}
                error={hasError('surname')}
                helperText={
                  hasError('surname') ? formState.errors.surname[0] : null
                }

                className={classes.textField}
                margin="normal"
                onChange={handleChange}
                type="text"

              />
              <TextField
                name="email"
                label="Email"
                value={formState.values.email || ''}
                error={hasError('email')}
                helperText={
                  hasError('email') ? formState.errors.email[0] : null
                }

                className={classes.textField}
                margin="normal"
                onChange={handleChange}
                type="text"

              />
               <Divider></Divider>
               <TextField
                name="sing_name"
                label="Sign Name"
                value={formState.values.sing_name || ''}
                error={hasError('sing_name')}
                helperText={
                  hasError('sing_name') ? formState.errors.sing_name[0] : null
                }
                className={classes.textField}
                margin="normal"
                onChange={handleChange}
                type="text"
              />
              <TextField
                name="sing_role"
                label="Sign Role"
                value={formState.values.sing_role || ''}
                error={hasError('sing_role')}
                helperText={
                  hasError('sing_role') ? formState.errors.sing_role[0] : null
                }
                className={classes.textField}
                margin="normal"
                onChange={handleChange}
                type="text"
              />
             <TextField
                name="sing_email"
                label="Sign Email"
                value={formState.values.sing_email || ''}
                error={hasError('sing_email')}
                helperText={
                  hasError('sing_email') ? formState.errors.sing_email[0] : null
                }
                className={classes.textField}
                margin="normal"
                onChange={handleChange}
                type="text"
              />
              <TextField
                name="sing_phone"
                label="Sign Phone"
                value={formState.values.sing_phone || ''}
                error={hasError('sing_phone')}
                helperText={
                  hasError('sing_phone') ? formState.errors.sing_phone[0] : null
                }
                className={classes.textField}
                margin="normal"
                onChange={handleChange}
                type="text"
              />
              <TextField
                name="sing_mobile"
                label="Sign Mobile"
                value={formState.values.sing_mobile || ''}
                error={hasError('sing_mobile')}
                helperText={
                  hasError('sing_mobile') ? formState.errors.sing_mobile[0] : null
                }
                className={classes.textField}
                margin="normal"
                onChange={handleChange}
                type="text"
              />
            </Paper>

          </div>
        </form>
      </div>
    </div>
  );
};

export default UserEdit;



