import { alertConstants } from '../_constants';

export const alertActions = {
    success,
    error,
    clear
};

function success(message) {
    return { type: alertConstants.SUCCESS, message,
        key: new Date().getTime() + Math.random() };
}

function error(message) {
    return { type: alertConstants.ERROR, message };
}

function clear(key) {
    return { type: alertConstants.CLEAR, key};
}