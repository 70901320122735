import { authHeader } from '../helpers';
import handleResponse from './handleResponse';
import { userConstants } from '_constants';

export const userService = {
    updatePassword,
    doAction,
    getAll,
    getById,
    login,
    logout,
    recover,
    _delete,
};

function updatePassword(password)
{
    return doAction(null, userConstants.ACTION_UPDATE_PASSWORD, {password: password})
}

function doAction(userId, action, data = []) {
    return kick({
        'action': action,
        'userId': userId,
        'data' : data
    });
}

function kick(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch( process.env.REACT_APP_API_ENDPOINT + `/api/user-kick`, requestOptions).then(handleResponse);
}

function getAll(departmentIds = [], text = '', page = '', per_page='')  {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/user?departmentIds=${departmentIds.join()}&text=${text}&page=${page}&per_page=${per_page}`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/user/${id}`, requestOptions).then(handleResponse);
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/user/${id}`, requestOptions).then(handleResponse);
}


function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function recover(username) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username })
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/recover`, requestOptions)
        .then(handleResponse);
}
